import imagePlaceholder from "../images/no-image.jpg";
import { optionsSize } from "../mocks/calendarOptions";
import { times } from "./date";

export const updateObject = (obj, payload) => {
  return { ...obj, ...payload };
};
export const objectEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

export const returnNewShopItem = (arr, payload) => {
  let oldValue;
  if (payload?.selector === "license") {
    oldValue = arr.find((el) => el.public_id === payload.public_id);
  } else {
    oldValue = arr.find(
      (el) => el.public_id === payload.public_id && el.size === payload.size
    );
  }

  if (oldValue) {
    let filterArr;
    if (payload?.selector === "license") {
      filterArr = arr.filter((el) => {
        return !(el.public_id === payload.public_id);
      });
    } else {
      filterArr = arr.filter((el) => {
        return !(
          el.public_id === payload.public_id && el.size === payload.size
        );
      });
    }

    return [...filterArr, { ...oldValue, ...payload }];
  } else {
    return [...arr, payload];
  }
};
export const returnNewLicenseItem = (arr, payload) => {
  const oldValue = arr.find((el) => el.public_id === payload.public_id);

  if (oldValue) {
    const filterArr = arr.filter((el) => {
      return !(el.public_id === payload.public_id);
    });

    return [...filterArr, { ...oldValue, ...payload }];
  } else return [...arr, payload];
};
export const isMobileTabletResponsive = (size) => {
  if (size?.length) {
    return size === "tablet" || size === "small" || size === "xsmall";
  }
};
export const returnNewArrayById = (
  arr,
  payload,
  selector = "format_public_id"
) => {
  const newValue = arr.find((el) => el[selector] === payload[selector]);

  if (newValue === undefined) {
    // если элемента нет - добавляет
    return [...arr, payload];
  } else {
    // если элемент есть заменяет его с обновленными значениями
    const filterArr = arr.filter((el) => el[selector] !== payload[selector]);
    return [...filterArr, { ...newValue, ...payload }];
  }
};

export const getFilteredData = (arr, filter, prop = "month") => {
  if (filter && filter !== "all") {
    return arr.filter((el) => el[prop] === filter);
  } else {
    return arr;
  }
};

export const getEventCategoryName = (value, values) => {
  if (Array.isArray(value)) {
    const foundValue = value
      .map((item) => item && values.find((i) => i.value === item)?.name)
      .filter((el) => el);
    return foundValue.length > 0 ? ` • ` + foundValue.join(" • ") : "";
  } else {
    const foundValue = values.find((i) => i.value === value)?.name;
    return foundValue ? `• ${foundValue}` : "";
  }
};
export const returnNewArrayByType = (arr, payload) => {
  let cb = () => {},
    filterCb = () => {};
  if (payload.selector === "shop") {
    cb = (el, payload) =>
      el.public_id === payload.public_id && el.size === payload.size;
    filterCb = (el, payload) =>
      !(el.public_id === payload.public_id && el.size === payload.size);
  } else {
    cb = (el, payload) => el.public_id === payload.public_id;
    filterCb = (el) => el.public_id !== payload.public_id;
  }

  const oldValue = arr.find(cb);

  if (oldValue) {
    const filterArr = arr.filter(filterCb);
    return [...filterArr, { ...oldValue, ...payload }];
  } else return [...arr, payload];
};
export const formatEventDate = (event, format = "DD.MM.YYYY") => {
  const start = event?.start_date;
  const end = event?.end_date;

  if (start && end) {
    /*   if (moment(event.start_date).isSame(event.end_date)) {
        return moment(event.start_date).format(format);
      } */

    return (
      times.getNumberFullDate(event.start_date) +
      " - " +
      times.getNumberFullDate(event.end_date)
    );
  } else if (start && !end) {
    return times.getNumberFullDate(event.start_date);
  }

  return "";
};
export const returnAuthConfig = () => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  };
};
export const updateLocalStorage = (store, action, payload) => {
  let oldData = [];
  let newData = [];

  if (localStorage.getItem(store)) {
    oldData = JSON.parse(localStorage.getItem(store));
  }
  if (action === "add") {
    if (payload.selector === "license") {
      newData = returnNewLicenseItem(oldData, payload);
    } else if (payload.selector === "shop") {
      newData = returnNewShopItem(oldData, payload);
    } else newData = returnNewArrayById(oldData, payload); // add + update array
  } else if (action === "delete") {
    newData = removeItemBasket(oldData, payload); // delete from array
  } else if (action === "update") {
    if (payload.selector === "license") {
      newData = oldData.map((el) => {
        if (el.public_id === payload.public_id) {
          return { ...el, ...payload };
        } else {
          return el;
        }
      });
    } else {
      newData = updateObject(oldData, payload); // update one object
    }
  } else if (action === "deleteAll") {
    newData = [];
  }
  localStorage.setItem(store, JSON.stringify(newData));
};

export const removeItemBasket = (arr, item) => {
  if (item.selector === "shop") {
    return arr.filter(
      (el) => !(el.public_id === item.public_id && el.size === item.size)
    );
  } else if (item?.check === "license") {
    return arr.filter((el) => el.public_id !== item?.license?.public_id);
  } else {
    return arr.filter((el) => el.public_id !== item.public_id);
  }
};

export const checkAndSetImgUrl = (evt) => {
  evt.target.src = imagePlaceholder;
};

export const updateObjectInArray = (array, action) => {
  return array.map((item) => {
    if (action.selector === `shop`) {
      if (item.public_id === action.public_id && item.size === action.size) {
        return { ...item, ...action };
      }
    } else {
      // return an updated value
      if (item.public_id === action.public_id) return { ...item, ...action };
    }
    return item;
  });
};

export const returnElementById = (arr, item, selector = `public_id`) => {
  if (arr.length) {
    if (item.selector === "shop") {
      return arr.find(
        (elem) => elem[selector] === item.public_id && elem.size === item.size
      );
    } else {
      return arr.find((elem) => elem[selector] === item.public_id);
    }
  } else {
    return null;
  }
};
export const capitalizeFirstLetter = (word) => {
  if (word && word !== undefined && word.length) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  } else return undefined;
};
export const searchTeam = (team_public_id, teams) => {
  const currentTeam = teams.find((t) => t.public_id === team_public_id);
  if (currentTeam) {
    const { number } = currentTeam;
    return { value: currentTeam["public_id"], label: number };
  } else return { value: null, label: "Команда не выбрана" };
};

export const searchSize = (size, sizes) => {
  let currentSize;

  if (sizes?.length > 0) {
    sizes.map((el) => {
      if (el.size === size) {
        currentSize = { value: el.count, label: el.size };
      }
    });
  } else {
    return null;
  }

  return currentSize;
};

export function processFile(file, setError = () => {}) {
  return new Promise((resolve, reject) => {
    if (!/image/i.test(file.type)) {
      setError("file", { message: "File " + file.name + " is not an image." });
      return false;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = function (event) {
      const blob = new Blob([event.target.result]);
      window.URL = window.URL || window.webkitURL;
      const blobURL = window.URL.createObjectURL(blob);

      var image = new Image();
      image.src = blobURL;

      image.onload = function () {
        const resized = resizeMe(image, file.type);
        resolve(resized);
      };
      image.onerror = (error) => {
        reject(error);
      };
    };
  });
}

export default async function getCroppedImg(imageSrc, cropAreaPixels) {
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => resolve(image);
      image.onerror = reject;
    });

  const image = await createImage(imageSrc);

  // Определяем целевые размеры: минимальное разрешение 480x640
  const MIN_WIDTH = 480;
  const MIN_HEIGHT = 640;

  // Вычисляем соотношение сторон оригинального изображения
  const aspectRatio = cropAreaPixels.width / cropAreaPixels.height;

  // Устанавливаем размеры канваса с учётом минимального разрешения
  let targetWidth = cropAreaPixels.width;
  let targetHeight = cropAreaPixels.height;

  if (cropAreaPixels.width < MIN_WIDTH || cropAreaPixels.height < MIN_HEIGHT) {
    if (aspectRatio > 1) {
      // Если изображение шире, чем выше
      targetWidth = MIN_WIDTH;
      targetHeight = MIN_WIDTH / aspectRatio;
    } else {
      // Если изображение выше, чем шире
      targetHeight = MIN_HEIGHT;
      targetWidth = MIN_HEIGHT * aspectRatio;
    }
  }

  const canvas = document.createElement("canvas");
  canvas.width = Math.round(targetWidth);
  canvas.height = Math.round(targetHeight);
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    cropAreaPixels.x,
    cropAreaPixels.y,
    cropAreaPixels.width,
    cropAreaPixels.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  const dataURL = canvas.toDataURL("image/jpeg");
  // Освобождаем память
  canvas.remove();
  return dataURL;
}

function resizeMe(img, type) {
  const canvas = document.createElement("canvas");

  const MAX_HEIGHT = 1000;
  const MAX_WIDTH = 1000;

  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height = Math.round((height *= MAX_WIDTH / width));
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width = Math.round((width *= MAX_HEIGHT / height));
      height = MAX_HEIGHT;
    }
  }

  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL(type, 0.7);
}
