import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  Heading,
  Image,
  Paragraph,
  ResponsiveContext,
  Text,
} from "grommet";
import { useContext, useEffect, useState } from "react";
import { Container } from "../../components/Container";
import { Calendar } from "../../components/Calendar";
import coverImg from "../../images/cover1.jpg";
import { PersonMorePopup } from "../../components/Popups/PersonMore";

import { Banner } from "../../components/Banner";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { useDataStore } from "../../hooks/useDataStore";
import { observer } from "mobx-react";
import renderHTML from "react-render-html";
import { REACT_APP_API } from "../../api";
import moment from "moment";

export const Management = observer(() => {
  const size = useContext(ResponsiveContext);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [managementItem, setManagementItem] = useState({});
  const sizes = ["small", "tablet", "desktop1200"];

  const { management, getManagementData } = useDataStore();

  const handleClickManagement = (item) => {
    setOpenPopup(true);
    setManagementItem(item);
  };

  useEffect(() => {
    getManagementData();
  }, []);

  return (
    <>
      <Banner cover={coverImg} />

      <Container>
        <Breadcrumbs />

        <Box>
          <Grid
            columns={{
              count: "fit",
              size: `${
                size === "medium" || size === "large" ? "500px" : "280px"
              }`,
            }}
            gap={"24px"}
            margin={{ bottom: "24px" }}
          >
            <Card
              direction={!sizes.includes(size) ? "row" : "column"}
              background={"#F8F8F8"}
              elevation={"none"}
            >
              <Box
                width={{ min: "250px", max: "643px" }}
                height={!sizes.includes(size) ? "auto" : "480px"}
              >
                <Image
                  src={`${REACT_APP_API}${management?.[0]?.picture}`}
                  fit={"cover"}
                  style={{ objectPosition: "center top" }}
                />
              </Box>
              <Box
                height={{ min: "410px" }}
                direction={"column"}
                justify={"between"}
                pad={{
                  top: "36px",
                  right: "40px",
                  bottom: "16px",
                  left: "52px",
                }}
                width={{ max: "749px" }}
              >
                <Text
                  margin={{ bottom: "20px" }}
                  style={{
                    textTransform: "uppercase",
                    color: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {management?.[0]?.position}
                </Text>
                <Box>
                  <Heading
                    level={2}
                    margin={{ bottom: "16px" }}
                    style={{
                      textTransform: "uppercase",
                      lineHeight: "1.2",
                      fontSize: "38px",
                    }}
                  >
                    {management?.[0]?.fio}
                  </Heading>
                  <Paragraph
                    margin={{ bottom: "20px" }}
                    color={"rgba(51, 51, 51, 0.5)"}
                    style={{
                      width: "100%",
                      maxWidth: "890px",
                      fontSize: "16px",
                    }}
                  >
                    {management?.[0]?.title}
                  </Paragraph>

                  <Box margin={{ bottom: "20px" }} height={{ min: "auto" }}>
                    <Box
                      direction={"row"}
                      style={{ width: "100%", maxWidth: "366px" }}
                    >
                      <Heading
                        level={"4"}
                        margin={{ bottom: "24px", right: "16px" }}
                        style={{ fontSize: "16px" }}
                      >
                        Дата рождения:
                      </Heading>
                      <Paragraph style={{ fontSize: "16px" }}>
                        {management?.[0]?.birth_date &&
                          moment(management?.[0]?.birth_date).format(
                            "DD/MM/YYYY"
                          )}
                      </Paragraph>
                    </Box>

                    <Box direction={"row"}>
                      <Heading
                        level={"4"}
                        margin={{ bottom: "24px", right: "36px" }}
                        style={{ fontSize: "16px" }}
                      >
                        Образование:
                      </Heading>
                      <Paragraph style={{ maxWidth: "100%", fontSize: "16px" }}>
                        {management?.[0]?.education}
                      </Paragraph>
                    </Box>
                  </Box>

                  <Box height={{ min: "auto" }}>
                    <Heading
                      level={"4"}
                      margin={{ bottom: "24px" }}
                      style={{ fontSize: "16px" }}
                    >
                      Профессиональная деятельность:
                    </Heading>

                    <Paragraph
                      margin={{ bottom: "24px" }}
                      style={{
                        width: "100%",
                        maxWidth: "890px",
                        fontSize: "16px",
                      }}
                    >
                      {management?.[0]?.description &&
                        renderHTML(management?.[0]?.description)}
                    </Paragraph>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid columns={{ count: "fit", size: "280px" }} gap={"medium"}>
            {management.slice(1).map((item) => (
              <Card
                /* onClick={() => handleClickManagement(item)}  */ background={
                  "#F8F8F8"
                }
                elevation={"none"}
                key={item.id}
              >
                <CardBody>
                  <Box height="350px">
                    <Image
                      src={`${REACT_APP_API}${item.picture}`}
                      style={{ borderRadius: "20px 20px 0 0" }}
                      fit="cover"
                    />
                  </Box>

                  <CardFooter align="start" direction="column" pad="30px 20px">
                    <Text
                      margin={{ bottom: "medium" }}
                      style={{
                        textTransform: "uppercase",
                        color: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {item.position}
                    </Text>
                    <CardHeader
                      style={{
                        textTransform: "uppercase",
                        lineHeight: "1.2",
                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                    >
                      {item.fio}
                    </CardHeader>
                  </CardFooter>
                </CardBody>
              </Card>
            ))}
          </Grid>
        </Box>

        {/*     <Partners /> */}

        <Calendar event_type="all" />
      </Container>

      {isOpenPopup && (
        <PersonMorePopup
          statePopupHandler={setOpenPopup}
          managementItem={managementItem}
        />
      )}
    </>
  );
});
