import { Box, Button, Heading, Image, Layer, Paragraph, Text } from "grommet";
import { FormClose } from "grommet-icons";
import styled from "styled-components";
import iconClose from "../../../images/svg/icon-close.svg";
import moment from "moment";
import { checkAndSetImgUrl } from "../../../utils/utils";
import { DefaultButton } from "../../DefaultButton";

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-top-right-radius: 12px;
  background-color: #db4c4c;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -51%);
    display: block;
    width: 26px;
    height: 26px;
    background-image: url("${iconClose}");
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }
`;

export const AthletesMorePopup = ({ statePopupHandler, personItem }) => {
  const handleClosePopup = () => {
    statePopupHandler(false);
  };

  return (
    <Layer
      onClickOutside={handleClosePopup}
      onEsc={handleClosePopup}
      full={true}
      plain={false}
      background={"transparent"}
      animation={"fadeIn"}
      position={"center"}
    >
      <Box
        overflow={"scroll"}
        height={{ min: "auto" }}
        background={"rgba(0, 0, 0, 0.3)"}
        align={"center"}
        onClick={handleClosePopup}
      >
        <Box
          height={{ min: "auto" }}
          background={"#FFFFFF"}
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "100%",
            maxWidth: "500px",
            borderRadius: "12px",
            position: "relative",
            cursor: "default",
          }}
        >
          <Box direction={"row"} justify={"end"} style={{ zIndex: "999" }}>
            <CloseButton
              icon={<FormClose color={"#DB4C4C"} />}
              onClick={handleClosePopup}
            />
          </Box>
          <Box height={{ min: "auto" }}>
            <Image
              src={`${process.env.REACT_APP_API}${personItem.picture}`}
              margin={{ bottom: "45px" }}
              onError={checkAndSetImgUrl}
              style={{
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                minHeight: "100%",
                maxHeight: "350px",
                objectFit: "cover",
                objectPosition: "left",
              }}
            />
          </Box>
          <Box pad={"large"}>
            <Paragraph
              margin={{ bottom: "22px" }}
              style={{
                textTransform: "uppercase",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <Text
                margin={{ bottom: "58px" }}
                style={{
                  textTransform: "uppercase",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                Спортивное звание:{" "}
                <Text weight={"bold"}>{personItem.title}</Text>
              </Text>
            </Paragraph>
            <Heading
              level={2}
              margin={{ bottom: "32px" }}
              style={{ textTransform: "uppercase", lineHeight: "1.2" }}
            >
              {personItem.name}
            </Heading>

            <Box
              direction={"row"}
              margin={{ bottom: "40px" }}
              height={{ min: "auto" }}
            >
              <Box style={{ width: "100%", maxWidth: "366px" }}>
                <Heading level={"4"} margin={{ bottom: "10px" }}>
                  Дата рождения:
                </Heading>
                <Paragraph>
                  {moment(personItem.birthday).format("DD/MM/YYYY")}
                </Paragraph>
              </Box>
            </Box>

            <Box height={{ min: "auto" }}>
              <Paragraph
                margin={{ bottom: "24px" }}
                style={{ width: "100%", maxWidth: "890px" }}
              >
                Регион — {personItem.region.name_ru}
              </Paragraph>
              <Paragraph
                margin={{ bottom: "24px" }}
                style={{ width: "100%", maxWidth: "890px" }}
              >
                Организация — {personItem.company}
              </Paragraph>
              <Paragraph
                margin={{ bottom: "24px" }}
                style={{ width: "100%", maxWidth: "890px" }}
              >
                Тренер в настоящий момент — {personItem.agent_name}
              </Paragraph>

              <DefaultButton
                onClick={handleClosePopup}
                style={{ maxWidth: "285px" }}
                label={"Закрыть"}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
