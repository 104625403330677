import styles from "./card.module.scss";

import { useState } from "react";
import { REACT_APP_API } from "../../api";
import { ReklamaPopup } from "../Popups/ReklamaPopup";

export const Card = ({ item }) => {
  const [isClickText, setIsClickText] = useState(false);
  const [partItem, setPartItem] = useState(null);

  const handleClickText = (e, item) => {
    e.preventDefault();
    setIsClickText(true);
    setPartItem(item);
  };

  return (
    <div className={styles.card}>
      <div className={styles.wrapImage}>
        <a
          className={styles.imageLink}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item?.reklama && !isClickText && (
            <span
              onClick={(e) => handleClickText(e, item)}
              className={styles.textMain}
            >
              Реклама
            </span>
          )}
          <div className={styles.imageBox}>
            <img
              src={`${REACT_APP_API}${item.picture_main}`}
              className={styles.image}
            />
          </div>
        </a>
      </div>
      {item.title && <span className={styles.title}>{item.title}</span>}

      {isClickText && partItem && (
        <ReklamaPopup
          closeHandler={() => setIsClickText(false)}
          item={partItem}
          styles={styles}
        />
      )}
    </div>
  );
};
