import { Box, Button, Heading, Image, Layer, Paragraph } from "grommet";
import { FormClose } from "grommet-icons";
import styled from "styled-components";
import iconClose from "../../../images/svg/icon-close.svg";
import renderHTML from "react-render-html";
import { REACT_APP_API } from "../../../api";
import moment from "moment";

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-top-right-radius: 12px;
  background-color: #db4c4c;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -51%);
    display: block;
    width: 26px;
    height: 26px;
    background-image: url("${iconClose}");
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }
`;

export const PersonMorePopup = ({ statePopupHandler, managementItem }) => {
  const handleClosePopup = () => {
    statePopupHandler(false);
  };

  return (
    <Layer
      onClickOutside={handleClosePopup}
      onEsc={handleClosePopup}
      full={true}
      plain={false}
      background={"transparent"}
      animation={"fadeIn"}
      position={"center"}
    >
      <Box
        // pad={{ vertical: '2rem' }}
        overflow={"auto"}
        height={{ min: "auto" }}
        background={"rgba(0, 0, 0, 0.3)"}
        align={"center"}
        // style={{ position: 'relative', cursor: 'default' }}
        onClick={handleClosePopup}
      >
        <Box
          height={{ min: "auto" }}
          background={"#FFFFFF"}
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "100%",
            maxWidth: "1153px",
            borderRadius: "12px",
            position: "relative",
            cursor: "default",
          }}
        >
          <Box direction={"row"} justify={"end"} style={{ zIndex: "999" }}>
            <CloseButton
              icon={<FormClose color={"#DB4C4C"} />}
              onClick={handleClosePopup}
            />
          </Box>
          <Box height={{ min: "auto" }}>
            <Image
              src={`${REACT_APP_API}${managementItem?.picture}`}
              margin={{ bottom: "45px" }}
              style={{
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                minHeight: "100%",
                maxHeight: "350px",
                objectFit: "cover",
                objectPosition: "left",
              }}
            />
          </Box>
          <Box pad={"large"}>
            <Paragraph
              margin={{ bottom: "22px" }}
              style={{
                textTransform: "uppercase",
                color: "rgba(0, 0, 0, 0.5)",
              }}
            >
              {managementItem?.position}
            </Paragraph>
            <Heading
              level={2}
              margin={{ bottom: "32px" }}
              style={{ textTransform: "uppercase", lineHeight: "1.2" }}
            >
              {managementItem?.fio}
            </Heading>
            <Paragraph
              size={"medium"}
              margin={{ bottom: "40px" }}
              style={{ width: "100%", maxWidth: "890px" }}
            >
              {managementItem?.title}
            </Paragraph>

            <Box
              direction={"row"}
              margin={{ bottom: "40px" }}
              height={{ min: "auto" }}
            >
              <Box style={{ width: "100%", maxWidth: "366px" }}>
                <Heading level={"4"} margin={{ bottom: "24px" }}>
                  Дата рождения:
                </Heading>
                <Paragraph>
                  {managementItem?.birth_date &&
                    moment(managementItem.birth_date).format("DD/MM/YYYY")}
                </Paragraph>
              </Box>

              <Box>
                <Heading level={"4"} margin={{ bottom: "24px" }}>
                  Образование:
                </Heading>
                <Paragraph>{managementItem?.education}</Paragraph>
              </Box>
            </Box>

            <Box height={{ min: "auto" }}>
              <Heading level={"4"} margin={{ bottom: "24px" }}>
                Профессиональная деятельность:
              </Heading>
              <Paragraph
                margin={{ bottom: "24px" }}
                style={{ width: "100%", maxWidth: "890px" }}
              >
                {managementItem?.description &&
                  renderHTML(managementItem.description)}
              </Paragraph>
              <Button
                onClick={handleClosePopup}
                style={{ maxWidth: "285px" }}
                label={"Закрыть"}
                primary
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
