import PhoneInput from "react-phone-number-input/input";
import moment from "moment";
import styled from "styled-components";
import iconClose from "../../../images/svg/icon-close.svg";
import styles from "./License.module.scss";
import { useContext, useRef } from "react";
import {
  Box,
  Layer,
  Button,
  Heading,
  Text,
  ResponsiveContext,
  Image as GrommetImage,
} from "grommet";
import { FormClose } from "grommet-icons";
import { observer } from "mobx-react";
import { makeValidationSchema } from "./makeValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { ErrorInfo } from "../../ErrorInfo";
import { DefaultButton } from "../../DefaultButton";
import { useDataStore } from "../../../hooks/useDataStore";
import { useEffect } from "react";
import { useRegionsStore } from "../../../hooks/useRegionsStore";
import { useState } from "react";
import { searchDeliveryRegion } from "../../../utils/data";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../const/routes";
import { useBasketStore } from "../../../hooks/useBasketStore";
import getCroppedImg, {
  isMobileTabletResponsive,
  processFile,
  updateLocalStorage,
} from "../../../utils/utils";
import { toJS } from "mobx";
import Cropper from "react-easy-crop";

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-top-right-radius: 0.625rem;
  background-color: #db4c4c;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26px;
    height: 26px;
    background-image: url("${iconClose}");
    background-repeat: no-repeat;
    background-position: center;
  }
`;

function calculateStartDate(activationDelay, ticketStartTime = null) {
  if (!activationDelay) {
    return "";
  }

  const now = Date.now();
  const minStartDate = new Date(now + activationDelay * 1000);
  const maxStartDate = new Date(now + 180 * 24 * 60 * 60 * 1000);

  let startDate;

  if (ticketStartTime) {
    const ticketStartDate = new Date(ticketStartTime);
    startDate = ticketStartDate;

    if (startDate < minStartDate) {
      startDate = minStartDate;
    }
  } else {
    startDate = minStartDate;
  }

  if (startDate > maxStartDate) {
    startDate = maxStartDate;
  }

  return startDate.toISOString();
}

export const LicensePopup = observer(({ closePopup, license, isEdit }) => {
  const [deliveryCost, setDeliveryCost] = useState(license.deliveryPrice);
  const {
    licenseDeliveryType,
    loadLicenseDeliveryType,
    loadDeliveryRegions,
    regions,
  } = useDataStore();

  const isDeliveryZero = deliveryCost > 0;

  const validateForm = makeValidationSchema(
    isDeliveryZero,
    license.online,
    license.activation_delay
  );

  const user = localStorage.userObj && JSON.parse(localStorage.userObj);
  const defaultValues =
    license?.licenses && license.licenses.length && license.licenses[0];

  const { updateGoods } = useBasketStore();

  const navigate = useNavigate();

  const [deliveryRegionId, setDeliveryRegionId] = useState(null);
  const [deliveryCityId, setDeliveryCityId] = useState(null);
  const [address, setAddress] = useState("");
  const [comment, setComment] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [image, setImage] = useState(license?.file);

  const [croppedImage, setCroppedImage] = useState(license?.file);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const fileInputRef = useRef(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
    defaultValues: {
      start_date: defaultValues
        ? moment(new Date(defaultValues.start_date)).format("YYYY-MM-DD")
        : moment(
            calculateStartDate(
              license.activation_delay,
              license.format_start_time
            )
          ).format("YYYY-MM-DD"),
      phone: (defaultValues && `+${defaultValues.phone}`) || "",
      email: license.email || user?.email || "",
      fio_ru:
        defaultValues && defaultValues.second_name
          ? `${defaultValues.last_name} ${defaultValues.first_name} ${defaultValues.second_name}`
          : defaultValues && !defaultValues.second_name
          ? `${defaultValues.last_name} ${defaultValues.first_name}`
          : "",
      b_date:
        (defaultValues &&
          moment(defaultValues.birth_date).format("YYYY-MM-DD")) ||
        "",
      relative_name: (defaultValues && defaultValues.relative_name) || "",
      passport_date:
        (defaultValues &&
          moment(defaultValues.passport_date).format("YYYY-MM-DD")) ||
        "",
      p_address: (defaultValues && defaultValues.passport_address) || "",
      fio_en:
        defaultValues && defaultValues.second_name_en
          ? `${defaultValues.last_name_en} ${defaultValues.first_name_en} ${defaultValues.second_name_en}`
          : defaultValues && !defaultValues.second_name_en
          ? `${defaultValues.last_name_en} ${defaultValues.first_name_en}`
          : "",
      city: defaultValues && defaultValues.city,
      passport_serial: (defaultValues && defaultValues.passport_serial) || "",
      passport_number: (defaultValues && defaultValues.passport_number) || "",
      relative_phone:
        defaultValues && defaultValues && `+${defaultValues.relative_phone}`,
      delivery_method: (license && license?.delivery_method) || "",
      passport_source: (defaultValues && defaultValues.passport_source) || "",
      passport_birth_place:
        (defaultValues && defaultValues.passport_birth_place) || "",
      passport_code: (defaultValues && defaultValues.passport_code) || "",
      file: license?.file,
    },
  });
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  const { location, getLocation, deliveryCities, loadDeliveryCities } =
    useRegionsStore();

  const handleMethodChange = (event) => {
    const delivery = licenseDeliveryType.find(
      (el) => el.public_id === event.target.value
    );

    if (delivery) {
      setDeliveryCost(delivery?.cost);
      setValue("delivery_method", event.target.value);
    }
  };

  const { addShop } = useBasketStore();

  const handleDeliveryRegion = (e) => {
    setDeliveryRegionId(e.target.value);
    setDeliveryCityId("");
  };

  const handleDeliveryCity = (e) => {
    setDeliveryCityId(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    loadLicenseDeliveryType();
    loadDeliveryRegions();
    getLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (license.deliveryRegionId) {
      setDeliveryRegionId(
        searchDeliveryRegion(regions, license.deliveryRegionId, "region")?.id
      );
      setDeliveryCityId(Number(license.deliveryCityId));
    } else {
      if (regions) {
        if (
          location &&
          location.region &&
          searchDeliveryRegion(regions, location.region.id, "region")
        ) {
          setDeliveryRegionId(
            searchDeliveryRegion(regions, location.region.id, "region")?.id
          );
        } else {
          setDeliveryRegionId(
            searchDeliveryRegion(regions, 524894, "region")?.id
          );
          //   setDeliveryCityId(524901);
        }
      } else {
        setDeliveryRegionId(null);
      }
    }
    // eslint-disable-next-line
  }, [location, regions]);

  useEffect(() => {
    const findCity = license.deliveryCityId
      ? searchDeliveryRegion(deliveryCities, license.deliveryCityId, "city")
      : searchDeliveryRegion(deliveryCities, location?.city?.id, "city");
    if (deliveryRegionId && location && findCity?.id) {
      setDeliveryCityId(findCity?.id);
    } else if (deliveryRegionId === 524894 && !license.delivery) {
      setDeliveryCityId(524901);
    } else if (license.deliveryCityId) {
      setDeliveryCityId(Number(license.deliveryCityId));
    } else {
      setDeliveryCityId(null);
    }
  }, [location, deliveryCities, deliveryRegionId, license]);

  useEffect(() => {
    deliveryRegionId && loadDeliveryCities(deliveryRegionId);
    setDeliveryCityId(null);
    // eslint-disable-next-line
  }, [deliveryRegionId]);

  const handleChangeFileInput = async (event) => {
    const picture = await processFile(event.target.files[0]);

    setCroppedImage(null);
    setImage(picture);
    setValue("file", null);
  };

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);

      setCroppedImage(croppedImage);
      setImage(croppedImage);
      setValue("file", croppedImage);
    } catch (error) {
      console.error("Crop failed:", error);
    }
  };
  const handleClearImage = () => {
    setImage(null);
    setValue("file", null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onSubmit = async ({
    file,
    passport_number,
    passport_serial,
    relative_phone,
    relative_name,
    phone,
    passport_date,
    passport_birth_place,
    passport_code,
    fio_ru,
    passport_source,
    city,
    b_date,
    fio_en,
    email,
    p_address,
    start_date,
    delivery_method,
  }) => {
    const namePartsRu = fio_ru.trim().split(/\s+/);
    const namePartsEn = fio_en.trim().split(/\s+/);

    if (!file) {
      setError("file", { message: "Выберите файл!" });
      return;
    }

    const birthDate = new Date(b_date);
    const startDate = new Date(start_date);

    let age = startDate.getFullYear() - birthDate.getFullYear();

    const isBeforeBirthday =
      startDate.getMonth() < birthDate.getMonth() ||
      (startDate.getMonth() === birthDate.getMonth() &&
        startDate.getDate() < birthDate.getDate());

    if (isBeforeBirthday) {
      age--; // Если день рождения не достигнут в этом году, уменьшаем возраст на 1
    }
    if (age < 5) {
      setError("b_date", { message: "Допускается возраст от 5 до 86 лет" });
      return;
    } else if (age > 86) {
      setError("b_date", { message: "Допускается возраст от 5 до 86 лет" });
      return;
    }

    let picture = null;
    if (file) {
      picture = file;
    } else if (license.file) {
      picture = license.file;
    }

    let item = {
      passport_number,
      passport_serial,
      phone: phone.slice(1),
      count: 1,
      passport_date: moment.utc(passport_date).toISOString(),
      passport_birth_place,
      passport_code,
      selector: "license",
      passport_source,
      insurance: {
        public_id: license.public_id,
      },
      city,
      birth_date: moment.utc(b_date).toISOString(),
      first_name: namePartsRu[1],
      last_name: namePartsRu[0],
      first_name_en: namePartsEn[1],
      last_name_en: namePartsEn[0],
      email,
      picture,
      passport_address: p_address,
      start_date: moment.utc(start_date).toISOString(),
    };

    if (namePartsRu[2]) {
      item = {
        ...item,
        second_name: namePartsRu[2],
      };
    }
    if (namePartsEn[2]) {
      item = {
        ...item,
        second_name_en: namePartsEn[2],
      };
    }
    if (relative_name) {
      item = {
        ...item,
        relative_name,
      };
    }
    if (relative_phone?.length === 12) {
      item = {
        ...item,
        relative_phone,
      };
    }

    if (isEdit) {
      let sendObj;

      if (deliveryCost <= 0 || license.online) {
        sendObj = {
          ...license,
          selector: "license",
          totalCost: license.price,
          logo: license.logo,
          count: 1,
          deliveryRegionId,
          deliveryCityId,
          delivery_method,
          address,
          comment,
          price: license.price,
          file: picture,
          promocode: null,
          deliveryPrice: deliveryCost,
          activation_delay: license.activation_delay,
          licenses: [item],
          email,
        };
      } else {
        sendObj = {
          ...license,
          selector: "license",
          totalCost: license.price,
          logo: license.logo,
          count: 1,
          deliveryRegionId,
          deliveryCityId,
          delivery_method,
          address,
          comment,
          price: license.price,
          file: picture,
          promocode: null,
          deliveryPrice: deliveryCost,
          activation_delay: license.activation_delay,
          licenses: [
            {
              ...item,
              delivery: {
                comment: comment,
                public_id: delivery_method,
                phone: phone.slice(1),
                last_name: namePartsRu?.[2] || "",
                address: address,
                first_name: namePartsRu?.[1] || "",
                location_id: Number(deliveryCityId) || 0,
              },
            },
          ],
          email,
        };
      }

      updateLocalStorage("basketGoods", "update", sendObj);
      updateGoods(sendObj);
      closePopup();
    } else {
      // добавление в корзину лицензии

      if (deliveryCost <= 0 || license.online) {
        addShop({
          ...license,
          selector: "license",
          totalCost: license.price,
          logo: license.logo,
          count: 1,
          deliveryRegionId,
          deliveryCityId,
          delivery_method,
          address,
          comment,
          file,
          promocode: null,
          price: license.price,
          deliveryPrice: deliveryCost,
          activation_delay: license.activation_delay,
          licenses: [item],
          email,
        });
      } else {
        addShop({
          ...license,
          selector: "license",
          totalCost: license.price,
          logo: license.logo,
          count: 1,
          deliveryRegionId,
          deliveryCityId,
          delivery_method,
          address,
          comment,
          file,
          promocode: null,
          price: license.price,
          deliveryPrice: deliveryCost,
          activation_delay: license.activation_delay,
          licenses: [
            {
              ...item,
              delivery: {
                comment: comment,
                public_id: delivery_method,
                phone: phone.slice(1),
                last_name: namePartsRu?.[2] || "",
                address: address,
                first_name: namePartsRu?.[1] || "",
                location_id: Number(deliveryCityId) || 0,
              },
            },
          ],
          email,
        });
      }

      navigate(routes.basket.path);
      closePopup();
    }
  };

  useEffect(() => {
    if (license?.deliveryType) {
      setAddress(license.address);
      setComment(license.comment);
      setDeliveryCost(license.deliveryPrice);
    }
  }, [license]);

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Layer
          full
          plain={false}
          background={"transparent"}
          onClickOutside={closePopup}
          onEsc={closePopup}
        >
          <Box
            direction={"row"}
            height={{ min: "100%" }}
            justify={"center"}
            background={"rgba(0, 0, 0, 0.3)"}
            align={"start"}
            pad={size === "small" ? "0" : { vertical: "medium" }}
            overflow={"auto"}
            style={{ paddingBottom: "30px" }}
          >
            <Box
              width={size === "small" ? "100%" : "60%"}
              background={"white"}
              round={size === "small" ? { size: "0" } : { size: "0.625rem" }}
              pad={"large"}
              style={{ position: "relative" }}
            >
              <CloseButton
                icon={<FormClose color={"#DB4C4C"} />}
                onClick={closePopup}
                style={size === "small" ? { borderRadius: 0 } : null}
              />
              <Box width="medium" height={"auto"} margin={{ bottom: "medium" }}>
                <GrommetImage
                  src={license.logo}
                  fit="cover"
                  alt="фото пример лицензии"
                />
              </Box>
              <Heading level={2} margin={{ bottom: "medium" }}>
                Регистрация лицензии Федерации Триатлона России
              </Heading>
              <Text size={"small"}>
                Особенно внимательно заполняйте эти поля. Следите за тем, чтобы
                тут были указаны именно ВАШИ контактные данные.
              </Text>
              <br />
              <Text size={"small"}>
                Эти данные будут сопоставлены с уже имеющимися у нас и если в
                нашей базе будет обнаружен указанный Вами e-mail, телефон или
                ФИО, то часть данных мы возьмем из имеющейся базы.
              </Text>
              <Heading level={3} margin={{ top: "small" }}>
                Тип выбранной лицензии: {license.name}
              </Heading>
              <Heading level={3}>{license.title}</Heading>
              <Heading level={3} margin={{ bottom: "small" }}>
                Стоимость: {license.price} руб.
              </Heading>

              <form
                className={styles.form}
                enctype="multipart/form-data"
                accept-charset="utf-8"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className={styles.wrap}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        marginBottom: "10px",
                        flexDirection: isMobileWidth ? "column" : "row",
                      }}
                    >
                      {image && (
                        <div
                          style={{
                            position: "relative",
                            width: "75px",
                            height: "100px",
                          }}
                        >
                          <img
                            alt="Фото на лицензию"
                            src={image}
                            width="75" // 3:4
                            height="100"
                            style={{ objectFit: "cover" }}
                          />
                          <FormClose
                            onClick={handleClearImage}
                            color={"#DB4C4C"}
                            style={{
                              position: "absolute",
                              right: "-10px",
                              top: "-10px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      )}
                      <label
                        onFocus={() => clearErrors("file")}
                        className={`${styles.labelFile} ${
                          errors.file?.message && styles.error
                        }`}
                      >
                        <span>Фото на лицензию в формате 3Х4 *</span>

                        <input
                          type="file"
                          name="file"
                          className={styles.file}
                          ref={fileInputRef}
                          onChange={(e) => handleChangeFileInput(e)}
                        />
                        {errors.file?.message && (
                          <ErrorInfo
                            mt="10px"
                            custom
                            error={errors.file.message}
                          />
                        )}
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {image && !croppedImage && (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "300px",
                            marginTop: "20px",
                          }}
                        >
                          <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            aspect={3 / 4}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                          />
                        </div>
                      )}

                      {image && !croppedImage && (
                        <DefaultButton
                          style={{ textAlign: "center", height: "44px" }}
                          onClick={handleCrop}
                          fill={true}
                        >
                          Кадрировать
                        </DefaultButton>
                      )}
                    </div>
                  </div>
                  <Text>Начало действия лицензии</Text>
                  <Controller
                    name="start_date"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.start_date?.message && styles.error
                        }`}
                        type="date"
                        name="start_date"
                        onFocus={() => clearErrors("start_date")}
                        pattern="\d{1,2}/\d{1,2}/\d{4}"
                        {...field}
                      />
                    )}
                  />
                  {errors.start_date?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.start_date.message}
                    />
                  )}
                  <Controller
                    name="fio_ru"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.fio_ru?.message && styles.error
                        }`}
                        type="text"
                        name="fio_ru"
                        onFocus={() => clearErrors("fio_ru")}
                        placeholder="Фамилия Имя Отчество*"
                        {...field}
                      />
                    )}
                  />
                  {errors.fio_ru?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.fio_ru.message}
                    />
                  )}
                  <Controller
                    name="fio_en"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.fio_en?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("fio_en")}
                        type="text"
                        name="fio_en"
                        placeholder="Фамилия Имя на Английском*"
                        {...field}
                      />
                    )}
                  />
                  {errors.fio_en?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.fio_en.message}
                    />
                  )}
                  <Text>Дата рождения</Text>
                  <Controller
                    name="b_date"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.b_date?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("b_date")}
                        type="date"
                        name="b_date"
                        pattern="\d{1,2}/\d{1,2}/\d{4}"
                        placeholder="Дата рождения*"
                        {...field}
                      />
                    )}
                  />
                  {errors.b_date?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.b_date.message}
                    />
                  )}
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.city?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("city")}
                        type="text"
                        name="city"
                        placeholder="Укажите Ваш город проживания*"
                        {...field}
                      />
                    )}
                  />
                  {errors.city?.message && (
                    <ErrorInfo mt="-10px" custom error={errors.city.message} />
                  )}
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        country="RU"
                        international={true}
                        withCountryCallingCode={true}
                        className={`${styles.input} ${
                          errors.phone?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("phone")}
                        type="tel"
                        name="phone"
                        placeholder="Ваш телефон*"
                        {...field}
                      />
                    )}
                  />
                  {errors.phone?.message && (
                    <ErrorInfo mt="-10px" custom error={errors.phone.message} />
                  )}
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.email?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("email")}
                        type="email"
                        name="email"
                        placeholder="Ваш E-mail*"
                        {...field}
                      />
                    )}
                  />
                  {errors.email?.message && (
                    <ErrorInfo mt="-10px" custom error={errors.email.message} />
                  )}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "20px",
                      maxWidth: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: errors.passport_serial?.message
                          ? 0
                          : "1rem",
                        width: "100%",
                        minWidth: "100px",
                      }}
                    >
                      <Controller
                        name="passport_serial"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`${styles.input} ${
                              errors.passport_serial?.message && styles.error
                            }`}
                            onFocus={() => clearErrors("passport_serial")}
                            type="text"
                            name="passport_serial"
                            placeholder="Серия паспорта*"
                            {...field}
                          />
                        )}
                      />
                      {errors.passport_serial?.message && (
                        <ErrorInfo
                          mt="-10px"
                          custom
                          error={errors.passport_serial.message}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: errors.passport_number?.message
                          ? 0
                          : "1rem",
                        width: "100%",
                        minWidth: "100px",
                      }}
                    >
                      <Controller
                        name="passport_number"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`${styles.input} ${
                              errors.passport_number?.message && styles.error
                            }`}
                            onFocus={() => clearErrors("passport_number")}
                            type="text"
                            name="passport_number"
                            placeholder="Номер паспорта*"
                            {...field}
                          />
                        )}
                      />
                      {errors.passport_number?.message && (
                        <ErrorInfo
                          mt="-10px"
                          custom
                          error={errors.passport_number.message}
                        />
                      )}
                    </div>
                  </div>
                  <Controller
                    name="passport_source"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.passport_source?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("passport_source")}
                        type="text"
                        name="passport_source"
                        placeholder="Орган, выдавший документ*"
                        {...field}
                      />
                    )}
                  />
                  {errors.passport_source?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.passport_source.message}
                    />
                  )}
                  <Text>Дата выдачи документа</Text>
                  <Controller
                    name="passport_date"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.passport_date?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("passport_date")}
                        type="date"
                        name="passport_date"
                        pattern="\d{1,2}/\d{1,2}/\d{4}"
                        placeholder="Дата выдачи документа*"
                        {...field}
                      />
                    )}
                  />
                  {errors.passport_date?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.passport_date.message}
                    />
                  )}
                  <Controller
                    name="passport_birth_place"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.passport_birth_place?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("passport_birth_place")}
                        type="text"
                        name="passport_birth_place"
                        placeholder="Место рождения*"
                        {...field}
                      />
                    )}
                  />
                  {errors.passport_birth_place?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.passport_birth_place.message}
                    />
                  )}
                  <Controller
                    name="passport_code"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.passport_code?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("passport_code")}
                        type="text"
                        name="passport_code"
                        placeholder="Код подразделения"
                        {...field}
                      />
                    )}
                  />
                  {errors.passport_code?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.passport_code.message}
                    />
                  )}
                  <Controller
                    name="p_address"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`${styles.input} ${
                          errors.p_address?.message && styles.error
                        }`}
                        onFocus={() => clearErrors("p_address")}
                        type="text"
                        name="p_address"
                        placeholder="Адрес по прописке*"
                        {...field}
                      />
                    )}
                  />
                  {errors.p_address?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      error={errors.p_address.message}
                    />
                  )}
                </div>

                <Text
                  margin={{ vertical: "1rem" }}
                  style={{ display: "block" }}
                >
                  Экстренная связь: введите данные контактного лица с которым
                  можно связаться в случае экстренной ситуации (родственник,
                  близкий друг)
                </Text>

                <div className={styles.wrap}>
                  {isDeliveryZero && (
                    <>
                      <Controller
                        name="relative_name"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`${styles.input} ${
                              errors.relative_name?.message && styles.error
                            }`}
                            onFocus={() => clearErrors("relative_name")}
                            type="text"
                            name="relative_name"
                            placeholder="Имя*"
                            {...field}
                          />
                        )}
                      />
                      {errors.relative_name?.message && (
                        <ErrorInfo
                          mt="-10px"
                          custom
                          error={errors.relative_name.message}
                        />
                      )}
                      <Controller
                        name="relative_phone"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput
                            country="RU"
                            international={true}
                            withCountryCallingCode={true}
                            className={`${styles.input} ${
                              errors.relative_phone?.message && styles.error
                            }`}
                            onFocus={() => clearErrors("relative_phone")}
                            type="tel"
                            placeholder="Телефон*"
                            {...field}
                          />
                        )}
                      />
                      {errors.relative_phone?.message && (
                        <ErrorInfo
                          mt="-10px"
                          custom
                          mb="20px"
                          error={errors.relative_phone.message}
                        />
                      )}
                    </>
                  )}
                  {!license.online && (
                    <Controller
                      name="delivery_method"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className={styles.select}
                          onChange={handleMethodChange}
                          onFocus={() => clearErrors("delivery_method")}
                        >
                          <option>Выберите способ доставки</option>
                          {licenseDeliveryType.length &&
                            licenseDeliveryType.map((el) => (
                              <option key={el.public_id} value={el?.public_id}>
                                {el?.name} ({el?.cost} руб.)
                              </option>
                            ))}
                        </select>
                      )}
                    />
                  )}
                  {errors.delivery_method?.message && (
                    <ErrorInfo
                      mt="-10px"
                      custom
                      mb="20px"
                      error={errors.delivery_method.message}
                    />
                  )}

                  {isDeliveryZero && (
                    <>
                      <select
                        className={styles.select}
                        name="delivery_region"
                        value={deliveryRegionId}
                        onChange={handleDeliveryRegion}
                      >
                        <option disabled selected>
                          Выберите регион
                        </option>
                        {regions.length &&
                          regions.map((el) => (
                            <option key={el.id} value={el?.id}>
                              {el?.name_ru}
                            </option>
                          ))}
                      </select>
                      <select
                        className={styles.select}
                        name="delivery_city"
                        value={deliveryCityId}
                        onChange={handleDeliveryCity}
                      >
                        <option selected>Выберите город доставки</option>
                        {deliveryCities.length &&
                          deliveryCities.map((el) => (
                            <option key={el.id} value={el?.id}>
                              {el?.name_ru}
                            </option>
                          ))}
                      </select>
                      <input
                        className={styles.input}
                        type="text"
                        name="delivery_address"
                        placeholder="Адрес доставки"
                        onChange={handleAddressChange}
                        value={address}
                      />
                      <label className={styles.textareaLabel} htmlFor="comment">
                        Примечания / Комментарий
                      </label>
                      <textarea
                        id="comment"
                        name="delivery_comment"
                        className={styles.textarea}
                        rows="5"
                        onChange={handleCommentChange}
                        value={comment}
                      />{" "}
                    </>
                  )}
                  {!isEdit && (
                    <div className={styles.checkboxWrap}>
                      <input
                        id="chb"
                        type="checkbox"
                        value={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        className={`${styles.checkbox} visually-hidden`}
                      />
                      <label className={styles.labelCheckbox} htmlFor="chb">
                        Соглашаюсь c{" "}
                        <a
                          href="/api/files/document/politic"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          политикой обработки персональных данных
                        </a>
                      </label>
                    </div>
                  )}
                </div>
                <div className={styles.costWrap}>
                  <span>К оплате:</span>
                  {deliveryCost && license.price ? (
                    <span className={styles.cost}>
                      &nbsp;{Number(license.price) + Number(deliveryCost)} руб.
                    </span>
                  ) : (
                    <span className={styles.cost}>
                      &nbsp;{Number(license.price)} руб.
                    </span>
                  )}
                </div>
                <div style={{ height: "44px" }}>
                  <DefaultButton
                    fill={true}
                    style={{ textAlign: "center" }}
                    type={"submit"}
                    disabled={!isChecked && !isEdit}
                  >
                    {isEdit ? "Сохранить изменения" : "Отправить"}
                  </DefaultButton>
                </div>
                <input hidden type="text" name="format" value={license.name} />
              </form>
            </Box>
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  );
});
