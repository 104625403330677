import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Loader from "react-js-loader";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../const/routes";
import { useBasketStore } from "../../hooks/useBasketStore";
import { useDataStore } from "../../hooks/useDataStore";
import { getTotalCost } from "../../utils/goods";

export const Add = observer(() => {
  const { id, type, shop_size, shop_count } = useParams();

  const { loadFormatsByFormatId, format } = useDataStore();

  const { addShop, currentShop, getCurrentShop, setCurrentShop } =
    useBasketStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (type === "ticket") {
      loadFormatsByFormatId(id);
    } else if (type === "shop") {
      getCurrentShop(id);
    }
    return () => setCurrentShop(null);
    //eslint-disable-next-line
  }, [id, type]);

  useEffect(() => {
    if (format) {
      const totalCost = getTotalCost(
        format.price,
        format.team_all ? format.max_count : 1,
        false
      );

      const sendObj = {
        ...format,
        selector: "ticket",
        insurance: false,
        totalCost: totalCost,
        logo: format.event_type.logo,
        event_public_id: format.event_public_id,
        format_public_id: id,
        count: format.team_all ? format.max_count : 1,
        promocode: null,
        hidden: false,
      };

      // добавление в корзину билета
      if (type === "ticket") {
        addShop(sendObj);
      }

      navigate(routes.basket.path);
    } else if (type === "shop" && currentShop && !Array.isArray(currentShop)) {
      const count = shop_count || 1;

      const totalCost = getTotalCost(currentShop.price, count);
      const shopElement = {
        ...currentShop,
        selector: "shop",
        logo: currentShop.picture,
        promocode: null,
        totalCost: totalCost,
        size: shop_size || currentShop?.proportions[0]?.name,
        count: count,
      };
      addShop(shopElement);
      navigate(routes.basket.path);
    }

    //eslint-disable-next-line
  }, [format, type, currentShop]);

  return (
    <div style={{ padding: "200px" }}>
      <Loader type="spinner-default" bgColor="#DB4C4C" />
    </div>
  );
});

//localhost:3000/add/ticket/triathlon800dev_team_sprint
//localhost:3000/add/shop/672F3344/S
