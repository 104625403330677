import { Button, Layer } from "grommet";
import { IconSvg } from "../../IconSvg";

export const ReklamaPopup = ({ closeHandler, item, styles }) => {
  return (
    <Layer
      onClickOutside={closeHandler}
      onEsc={closeHandler}
      style={{ width: "500px", height: "500px" }}
      background="transparent"
      animation="fadeIn"
      borderRadius="16"
      position="center"
      fill={false}
    >
      <div
        style={{
          backgroundColor: "#fff",
          opacity: "0.9",
          maxWidth: "500px",
          height: "350px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "16px",
        }}
      >
        <Button
          icon={<IconSvg src="close" />}
          onClick={closeHandler}
          className={styles.closeButton}
        ></Button>

        <div className={styles.reklama}>
          <div className={styles.items}>{item?.reklama?.name}</div>
          <div className={styles.items}>{item?.reklama?.inn}</div>
          <div className={styles.items}>{item?.reklama?.gov_id}</div>
        </div>
      </div>
    </Layer>
  );
};
