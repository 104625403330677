import React, { useState, useEffect, useRef, useMemo } from "react";
import { toJS } from "mobx";
import { TextInput } from "../Inputs/TextInput";
import { Goods } from "./Goods";
import {
  StyledActivity,
  StyledAside,
  StyledAsideSubTitle,
  StyledAsideTitle,
  StyledAsideTitleWrap,
  StyledButton,
  StyledCertificate,
  StyledCost,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledListItemWrap,
  StyledParagraph,
  StyledSaleWrap,
  StyledSendBlock,
  StyledText,
  StyledTotalCost,
  StyledTotalWrap,
  StyledWarningMessage,
  StyledWrapper,
} from "./styles";
import { makeValidationSchema } from "./makeValidationSchema";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { observer } from "mobx-react";
import { StyledDiscount, StyledPopupWrapper } from "./Goods/styles";
import { Layer } from "grommet";
import { checkActualTicketsCount, getPaymentObject } from "../../utils/basket";
import { saleCounter } from "../../utils/goods";
import { DefaultButton } from "../DefaultButton";

import styles from "./index.module.scss";
import { useRegionsStore } from "../../hooks/useRegionsStore";
import { useDataStore } from "../../hooks/useDataStore";
import { useBasketStore } from "../../hooks/useBasketStore";
import { searchDeliveryRegion } from "../../utils/data";
import { InputPhone } from "../Inputs/PhoneInput";
import { HiddenForm } from "../HiddenForm";

import mir from "../../images/svg/Mir__color.svg";
import mc from "../../images/svg/MC__color.svg";
import visa from "../../images/svg/Visa__color.svg";
import uniteller from "../../images/svg/Uniteller____color.svg";

export const BasketComponent = observer(
  ({
    goods,
    removeShop,
    updateGoods,
    giftDiscount = { value: 0 },
    errorPayment,
    promoCodeError,
    setPromoCodeError,
    sendPromoCode,
    promoCodes,
    removePromoCode,
    checkTicketsBeforePaymentByFormatId,
    payment,
    requestPayment,
    setError,
  }) => {
    const {
      getDeliveryPrice,
      deliveryPrice,
      ticketFormatsInBasket,
      setDeliveryPrice,
      removeCurrentGoods,
    } = useBasketStore();

    const [deliveryCost, setDeliveryCost] = useState(null);
    const [securityPaymentPopup, setSecurityPaymentPopup] = useState(false);
    /*  const [isInfoPopup, setIsInfoPopup] = useState(true); */

    const isShop = goods.some((el) => {
      if (el.delivery && el.selector === "shop") {
        return true;
      }
      return false;
    });

    const isDeliveryPrice = goods.some((el) => {
      if (el.delivery && el.selector === "shop") {
        return true;
      } else if (el.selector === "license") {
        return true;
      }
      return false;
    });

    const isDelivery = deliveryPrice && deliveryPrice?.cost;
    const formButton = useRef();
    const validateForm = makeValidationSchema(isShop, isDelivery);

    const user = localStorage.userObj && JSON.parse(localStorage.userObj);

    const { location, getLocation, deliveryCities, loadDeliveryCities } =
      useRegionsStore();
    const { loadDeliveryRegions, regions } = useDataStore();

    const {
      handleSubmit,
      control,
      formState: { errors },
      clearErrors,
      setValue,
    } = useForm({
      resolver: yupResolver(validateForm),
      shouldFocusError: false,
      defaultValues: {
        email: user?.email || "",
        name: user?.name || "",
        phone: `+${user?.phone}` || "",
        lastName: user?.lastName || "",
      },
    });

    const [totalDiscount, setTotalDiscount] = useState(0);
    const [isOpenPopup, setIsOpenPopup] = useState(true);
    const [deliveryRegionId, setDeliveryRegionId] = useState(null);
    const [deliveryCityId, setDeliveryCityId] = useState(null);
    const [address, setAddress] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isCheckedProcessing, setIsCheckedProcessing] = useState(false);
    const [isCheckedPolitical, setIsCheckedPolitical] = useState(false);
    const [isCheckedReklama, setIsCheckedReklama] = useState(false);

    const paymentFormKeys =
      requestPayment?.form && Object.keys(requestPayment.form);

    const moreDelivery = useMemo(
      () =>
        goods.length &&
        goods.reduce((acc, curr) => {
          if (acc.selector === "license" && curr.selector === "license") {
            if (acc?.deliveryPrice > curr?.deliveryPrice) {
              return acc;
            } else {
              return curr;
            }
          } else {
            if (acc.selector === "license") {
              return acc;
            } else if (curr.selector === "license") {
              return curr;
            }
          }
          return {};
        }),
      [goods]
    );

    useEffect(() => {
      const filteredPreRegGoods = goods?.filter((el) => el.preregistration);
      const preReg = filteredPreRegGoods.filter(
        (el) => el.preReg === "ok" || el.preReg === "later"
      );

      if (preReg.length === filteredPreRegGoods.length) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }, [goods, isUpdate]);

    useEffect(() => {
      if (moreDelivery?.deliveryPrice && deliveryPrice?.cost) {
        if (moreDelivery?.deliveryPrice > deliveryPrice?.cost) {
          setDeliveryCost(moreDelivery?.deliveryPrice);
        } else {
          setDeliveryCost(deliveryPrice?.cost);
        }
      } else if (moreDelivery?.deliveryPrice && !deliveryPrice?.cost) {
        setDeliveryCost(moreDelivery?.deliveryPrice);
      } else {
        setDeliveryCost(deliveryPrice?.cost);
      }
    }, [moreDelivery, deliveryPrice]);

    useEffect(() => {
      if (formButton && requestPayment) {
        formButton.current.click();
      }
    }, [formButton, requestPayment]);

    const onSubmit = ({ email, phone, name, lastName }) => {
      if (isDelivery) {
        if (checkActualTicketsCount(ticketFormatsInBasket, goods)) {
          const sendGoods = getPaymentObject(goods, promoCodes, email);

          if (sendGoods?.licenses?.length && moreDelivery) {
            if (moreDelivery.deliveryPrice > deliveryPrice?.cost) {
              sendGoods.delivery = {
                ...moreDelivery.licenses[0].delivery,
              };
              payment(sendGoods);
            } else {
              if (deliveryCost <= 0) {
                sendGoods.delivery = undefined;
              } else {
                sendGoods.delivery = {
                  location_id: deliveryCityId,
                  address,
                  first_name: name,
                  last_name: lastName,
                  phone: phone !== "+undefined" && phone?.slice(1),
                };
              }

              payment(sendGoods);
            }
          } else {
            if (deliveryCost <= 0) {
              sendGoods.delivery = undefined;
            } else {
              sendGoods.delivery = {
                location_id: deliveryCityId,
                address,
                first_name: name,
                last_name: lastName,
                phone: phone !== "+undefined" && phone?.slice(1),
              };
            }
            payment(sendGoods);
          }
        } else {
          console.warn("Указанное количество билетов недоступно");
          //setRequiredTicketCountError("Указанное количество билетов недоступно")
        }
      } else {
        if (checkActualTicketsCount(ticketFormatsInBasket, goods)) {
          const sendGoods = getPaymentObject(goods, promoCodes, email);

          if (!sendGoods.delivery && moreDelivery) {
            if (moreDelivery?.licenses && moreDelivery?.licenses[0]?.delivery) {
              sendGoods.delivery = {
                ...moreDelivery.licenses[0].delivery,
              };
            }
          }
          payment(sendGoods);
        } else {
          console.warn("Указанное количество билетов недоступно");
          // setRequiredTicketCountError("Указанное количество билетов недоступно")
        }
      }
    };

    useEffect(() => {
      loadDeliveryRegions();
      getLocation();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (regions) {
        if (
          location &&
          location.region &&
          searchDeliveryRegion(regions, location.region.id, "region")
        ) {
          setDeliveryRegionId(
            searchDeliveryRegion(regions, location.region.id, "region")?.id
          );
        } else {
          setDeliveryRegionId(
            searchDeliveryRegion(regions, 524894, "region")?.id
          );
          setDeliveryCityId(524901);
        }
      } else {
        setDeliveryRegionId(null);
      }
      // eslint-disable-next-line
    }, [location, regions]);

    useEffect(() => {
      const findCity = searchDeliveryRegion(
        deliveryCities,
        location?.city?.id,
        "city"
      );
      if (deliveryRegionId && location && findCity?.id) {
        setDeliveryCityId(findCity?.id);
      } else if (deliveryRegionId === 524894) {
        setDeliveryCityId(524901);
      } else {
        setDeliveryCityId(null);
      }
    }, [location, deliveryCities, deliveryRegionId]);

    useEffect(() => {
      deliveryRegionId && loadDeliveryCities(deliveryRegionId);
      setDeliveryCityId(null);
      // eslint-disable-next-line
    }, [deliveryRegionId]);

    useEffect(() => {
      return () => {
        setPromoCodeError({ message: "", id: "" });
      };
      //eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (goods.some((el) => el.delivery) && deliveryCityId) {
        const deliveryPriceObject = { city: { id: deliveryCityId }, goods: [] };

        goods.forEach((el) => {
          if (el.delivery) {
            deliveryPriceObject.goods.push({
              public_id: el.public_id,
              count: el.count,
            });
          }
        });
        getDeliveryPrice(deliveryPriceObject);
      }
      // eslint-disable-next-line
    }, [goods, deliveryCityId]);

    useEffect(() => {
      // счетчик скидки
      const discount = promoCodes
        .map((sale) => {
          if (sale.discount_form === "percent") {
            const oneGoods = goods.find(
              (el) => el.public_id === sale.public_id
            );
            return saleCounter(oneGoods, sale);
          } else {
            return sale.discount && sale.discount > 0 ? sale.discount : 0;
          }
        })
        .reduce((a, b) => a + b, 0)
        .toFixed(0);

      setTotalDiscount(+discount);
    }, [promoCodes, goods]);

    const handleButtonClose = () => {
      setError({ message: "" });
      setIsOpenPopup(false);
    };

    useEffect(() => {
      if (errorPayment.message) {
        setIsOpenPopup(true);
      }
    }, [errorPayment]);

    const handleDeliveryRegion = (e) => {
      setDeliveryRegionId(e.target.value);
      setDeliveryCityId("");
    };

    const handleDeliveryCity = (e) => {
      setDeliveryCityId(e.target.value);
    };

    const totalCost = goods.reduce((a, b) => {
      return a + b.totalCost;
    }, 0);

    useEffect(() => {
      if (!isDeliveryPrice) {
        setDeliveryCost(null);
      }
      return () => setDeliveryPrice(null);
    }, [isShop, isDeliveryPrice]);

    const handleChange = () => {
      setIsUpdate(!isUpdate);
    };

    return (
      <StyledWrapper>
        {goods.length ? (
          <ul>
            {goods.map((item, i) => (
              <Goods
                checkTicketsBeforePaymentByFormatId={
                  checkTicketsBeforePaymentByFormatId
                }
                ticketFormatsInBasket={ticketFormatsInBasket}
                removePromoCode={removePromoCode}
                promoCodes={promoCodes}
                sendPromoCode={sendPromoCode}
                promoCodeError={promoCodeError}
                removeShop={removeShop}
                updateGoods={updateGoods}
                key={`${i}${item.public_id}`}
                item={item}
                handleChange={handleChange}
                removeCurrentGoods={removeCurrentGoods}
              />
            ))}
          </ul>
        ) : (
          <h2>Ваша корзина пуста</h2>
        )}
        {goods.length ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <StyledAside>
                <StyledAsideTitleWrap>
                  <StyledAsideTitle>Ваша корзина</StyledAsideTitle>
                  <StyledAsideSubTitle>
                    Доступные способы оплаты можно выбрать при оформлении заказа
                  </StyledAsideSubTitle>

                  <StyledWarningMessage>
                    <StyledCertificate>
                      На все наши мероприятия необходимо иметь при себе
                      медицинскую справку
                    </StyledCertificate>
                  </StyledWarningMessage>
                </StyledAsideTitleWrap>

                <StyledList>
                  {goods.filter((el) => el.selector === "ticket").length ? (
                    <StyledListItem>
                      <StyledListItemWrap>
                        <h3>Мероприятия</h3>
                        <StyledText>
                          <span>
                            {
                              goods.filter((el) => el.selector === "ticket")
                                .length
                            }
                          </span>
                          &nbsp;шт.
                        </StyledText>
                      </StyledListItemWrap>
                      <ul>
                        {goods.map((el, i) =>
                          el.selector === "ticket" ? (
                            <StyledActivity key={`${el._id}${i}`}>
                              <span>
                                {el.size ? `${el.title} ${el.size}` : el.title}
                              </span>
                              <StyledText>{el.totalCost} &#8381;</StyledText>
                            </StyledActivity>
                          ) : null
                        )}
                      </ul>
                    </StyledListItem>
                  ) : null}
                  {goods.filter((el) => el.selector === "license").length ? (
                    <>
                      <li className={styles.asideItem}>
                        <div className={styles.asideItemTitleWrap}>
                          <h3>Лицензии</h3>
                          <span>
                            <span>
                              {
                                goods.filter((el) => el.selector === "license")
                                  .length
                              }
                            </span>{" "}
                            &nbsp;шт.
                          </span>
                        </div>
                        <ul>
                          {goods.map((el, i) =>
                            el.selector === "license" ? (
                              <li
                                className={styles.activityItem}
                                key={`${el._id}${i}`}
                              >
                                <span className={styles.activityName}>
                                  {el.size
                                    ? `${el.title} ${el.size}`
                                    : el.title}
                                </span>
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {Number(el.totalCost)} &#8381;
                                </span>
                              </li>
                            ) : null
                          )}
                          {/*   {goods.map((el, i) =>
                            el.selector === "license" ? (
                              <li
                                className={styles.activityItem}
                                key={`${el._id}${i}`}
                              >
                                <span>Доставка</span>
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {el.deliveryPrice} &#8381;
                                </span>
                              </li>
                            ) : null
                          )} */}
                        </ul>
                      </li>
                    </>
                  ) : null}
                  {goods.filter((el) => el.selector === "shop").length ? (
                    <>
                      <li className={styles.asideItem}>
                        <div className={styles.asideItemTitleWrap}>
                          <h3>Товары</h3>
                          <span>
                            <span>
                              {
                                goods.filter((el) => el.selector === "shop")
                                  .length
                              }
                            </span>{" "}
                            &nbsp;шт.
                          </span>
                        </div>
                        <ul>
                          {goods.map((el, i) =>
                            el.selector === "shop" ? (
                              <li
                                className={styles.activityItem}
                                key={`${el._id}${i}`}
                              >
                                <span className={styles.activityName}>
                                  {el.size
                                    ? `${el.title} ${el.size}`
                                    : el.title}
                                </span>
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {el.totalCost} &#8381;
                                </span>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </li>
                      {isShop ? (
                        <>
                          <li className={styles.asideItem}>
                            <div className={styles.asideItemTitleWrap}>
                              <h3>Выберите город доставки</h3>
                              <span></span>
                            </div>

                            <div className={styles.deliverySelect}>
                              <select
                                className={styles.select}
                                name="delivery_region"
                                value={deliveryRegionId}
                                onChange={handleDeliveryRegion}
                              >
                                <option disabled selected>
                                  Выберите регион
                                </option>
                                {regions.length &&
                                  regions.map((el) => (
                                    <option key={el.id} value={el?.id}>
                                      {el?.name_ru}
                                    </option>
                                  ))}
                              </select>
                              <select
                                className={styles.select}
                                name="delivery_city"
                                value={deliveryCityId}
                                onChange={handleDeliveryCity}
                              >
                                <option selected>
                                  Выберите город доставки
                                </option>
                                {deliveryCities.length &&
                                  deliveryCities.map((el) => (
                                    <option key={el.id} value={el?.id}>
                                      {el?.name_ru}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </li>

                          {deliveryPrice && (
                            <li className={styles.asideItem}>
                              <div className={styles.asideItemTitleWrap}>
                                <h3>Заполните данные</h3>
                              </div>
                              <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                  <TextInput
                                    iconSrc="user"
                                    error={errors.name?.message}
                                    handleFocus={() => clearErrors("name")}
                                    handleChange={(e) =>
                                      setValue(e.target.value)
                                    }
                                    name="name"
                                    marginErrorBottom="10px"
                                    id="profile-name"
                                    defaultValue={user?.name}
                                    label="Имя"
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="lastName"
                                control={control}
                                render={({ field }) => (
                                  <TextInput
                                    iconSrc="user"
                                    error={errors.lastName?.message}
                                    handleFocus={() => clearErrors("lastName")}
                                    handleChange={(e) =>
                                      setValue(e.target.value)
                                    }
                                    name="lastName"
                                    marginErrorBottom="10px"
                                    id="profile-lastName"
                                    defaultValue={user?.lastName}
                                    label="Фамилия"
                                    {...field}
                                  />
                                )}
                              />
                              <div className={styles.inputGroup}>
                                <label
                                  className={styles.textareaLabel}
                                  htmlFor="address-text"
                                >
                                  Ваш адрес
                                </label>
                                <textarea
                                  className={styles.textarea}
                                  rows="5"
                                  autoComplete="off"
                                  name="address"
                                  id="address-text"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                              </div>
                            </li>
                          )}

                          <li className={styles.asideItem}>
                            <div className={styles.asideItemTitleWrap}>
                              <h3>Оплата заказа</h3>
                              <span>
                                <span>{goods.length}</span> шт.
                              </span>
                            </div>
                            <ul>
                              {goods.filter((el) => el.selector === "ticket")
                                .length ? (
                                <li className={styles.activityItem}>
                                  <span className={styles.activityName}>
                                    Мероприятия:
                                  </span>
                                  <span>
                                    {goods
                                      .filter((el) => el.selector === "ticket")
                                      .reduce((a, b) => a + b.totalCost, 0)
                                      .toFixed(0)}{" "}
                                    &#8381;
                                  </span>
                                </li>
                              ) : null}
                              {goods.filter((el) => el.selector === "license")
                                .length ? (
                                <li className={styles.activityItem}>
                                  <span className={styles.activityName}>
                                    Лицензии:
                                  </span>
                                  <span>
                                    {goods
                                      .filter((el) => el.selector === "license")
                                      .reduce((a, b) => a + b.totalCost, 0)
                                      .toFixed(0)}{" "}
                                    &#8381;
                                  </span>
                                </li>
                              ) : null}
                              {/* { <li className={styles.activityItem}>
                                <span className={styles.activityName}>
                                  Сертификаты:
                                </span>
                                <span>
                                  {goods
                                    .filter(
                                      (el) => el.selector === "certificate"
                                    )
                                    .reduce((a, b) => a + b.totalCost, 0)
                                    .toFixed(0)}{" "}
                                  &#8381;
                                </span>
                              </li>} */}

                              <li className={styles.activityItem}>
                                <span className={styles.activityName}>
                                  Товары:
                                </span>
                                <span>
                                  {goods
                                    .filter((el) => el.selector === "shop")
                                    .reduce((a, b) => a + b.totalCost, 0)
                                    .toFixed(0)}{" "}
                                  &#8381;
                                </span>
                              </li>
                            </ul>
                          </li>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {goods.filter((el) => el.selector === "certificate")
                    .length ? (
                    <li>
                      <div>
                        <h3>Сертификаты</h3>
                        <span>
                          <span>
                            {
                              goods.filter(
                                (el) => el.selector === "certificate"
                              ).length
                            }
                          </span>
                          шт.
                        </span>
                      </div>
                      <ul>
                        {goods.map((el, i) =>
                          el.selector === "certificate" ? (
                            <li key={`${el._id}${i}`}>
                              <span>
                                {el.size ? `${el.title} ${el.size}` : el.title}
                              </span>
                              <span>{el.totalCost} &#8381;</span>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </li>
                  ) : null}
                </StyledList>

                <StyledTotalWrap>
                  {totalDiscount !== 0 && (
                    <StyledSaleWrap>
                      <StyledDiscount>Скидка: </StyledDiscount>
                      <StyledDiscount>{totalDiscount} &#8381;</StyledDiscount>
                    </StyledSaleWrap>
                  )}
                  {giftDiscount.value !== 0 && (
                    <StyledSaleWrap>
                      <span>Подарочная скидка ({giftDiscount.volume}):</span>
                      <span>{giftDiscount.value.toLocaleString()} &#8381;</span>
                    </StyledSaleWrap>
                  )}
                  <div
                    style={{ marginBottom: "30px" }}
                    className={styles.activityItem}
                  >
                    {deliveryCost && isDeliveryPrice && (
                      <>
                        {" "}
                        <span className={styles.activityName}>Доставка:</span>
                        {deliveryCost && (
                          <span>{deliveryCost} &#8381;</span>
                        )}{" "}
                      </>
                    )}
                  </div>
                  <StyledTotalCost>
                    <span>Общая стоимость:</span>
                    {isDeliveryPrice && deliveryCost ? (
                      <StyledCost>
                        {(
                          totalCost +
                          deliveryCost -
                          totalDiscount -
                          giftDiscount.value
                        ).toLocaleString()}{" "}
                        &#8381;
                      </StyledCost>
                    ) : (
                      <span>
                        {(
                          totalCost -
                          totalDiscount -
                          giftDiscount.value
                        ).toLocaleString()}{" "}
                        &#8381;
                      </span>
                    )}
                  </StyledTotalCost>
                </StyledTotalWrap>
                <StyledSendBlock>
                  {isShop ? (
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <InputPhone
                          country="RU"
                          international={true}
                          withCountryCallingCode={true}
                          error={errors.phone?.message}
                          id="basket-tel"
                          name="phone"
                          onFocus={() => clearErrors("phone")}
                          marginErrorBottom="10px"
                          iconSrc="rect"
                          iconHeight="40px"
                          iconLeft="20px"
                          iconTop="8px"
                          {...field}
                        />
                      )}
                    />
                  ) : null}
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        iconSrc="email"
                        error={errors.email?.message}
                        handleFocus={() => clearErrors("email")}
                        handleChange={(e) => setValue(e.target.value)}
                        name="email"
                        id="basket-email"
                        marginErrorBottom="10px"
                        defaultValue={user?.email}
                        label="Введите ваш email"
                        {...field}
                      />
                    )}
                  />

                  <StyledParagraph>
                    <div className={styles.checkboxWrap}>
                      <input
                        id="processing"
                        type="checkbox"
                        value={isCheckedProcessing}
                        checked={isCheckedProcessing}
                        onChange={() =>
                          setIsCheckedProcessing(!isCheckedProcessing)
                        }
                        className={`${styles.checkbox} visually-hidden`}
                      />
                      <label
                        className={styles.labelCheckbox}
                        htmlFor="processing"
                      >
                        Я согласен с{" "}
                        <a
                          href="/api/files/document/pers"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          обработкой персональных данных
                        </a>{" "}
                        и с{" "}
                        <a
                          href="/api/files/document/politic"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          политикой
                        </a>{" "}
                        обработки персональных данных
                      </label>
                    </div>
                    <div className={styles.checkboxWrap}>
                      <input
                        id="reklama"
                        type="checkbox"
                        value={isCheckedReklama}
                        checked={isCheckedReklama}
                        onChange={() => setIsCheckedReklama(!isCheckedReklama)}
                        className={`${styles.checkbox} visually-hidden`}
                      />
                      <label className={styles.labelCheckbox} htmlFor="reklama">
                        <span style={{ marginLeft: "7px" }}>я даю </span>
                        <a
                          href="/api/files/document/advertising"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          согласие на получение
                        </a>{" "}
                        рекламы
                      </label>
                    </div>
                  </StyledParagraph>
                  <DefaultButton
                    style={{
                      height: "44px",
                      textAlign: "center",
                      marginBottom: "5px",
                      marginTop: "20px",
                    }}
                    fill={true}
                    type="submit"
                    disabled={
                      requestPayment || isDisabled || !isCheckedProcessing
                    }
                  >
                    Оплатить
                  </DefaultButton>
                  <div className={styles.paymentLogotypes}>
                    <img alt="visa" src={visa} className={styles.paymentImg} />
                    <img alt="mc" src={mc} className={styles.paymentImg} />
                    <img alt="mir" src={mir} className={styles.paymentImg} />
                    <img
                      alt="uniteller"
                      src={uniteller}
                      className={styles.paymentImg}
                    />
                  </div>
                  <div className={styles.paymentLogotypes}>
                    <span
                      onClick={() => setSecurityPaymentPopup(true)}
                      className={styles.securityPayment}
                    >
                      Безопасность платежей
                    </span>
                  </div>
                </StyledSendBlock>
              </StyledAside>
            </form>
            {errorPayment && errorPayment.message && isOpenPopup && (
              <Layer animation="fadeIn">
                <StyledPopupWrapper>
                  <div>{errorPayment.message}</div>
                  <StyledButton onClick={handleButtonClose}>
                    Вернуться в корзину
                  </StyledButton>
                </StyledPopupWrapper>
              </Layer>
            )}
            {securityPaymentPopup && (
              <Layer animation="fadeIn">
                <StyledPopupWrapper>
                  <div>
                    <h4>Уважаемый клиент!</h4>
                    <p className={styles.securityP}>
                      Вы можете оплатить свой заказ онлайн с помощью банковской
                      карты через платежный сервис компании Uniteller. После
                      подтверждения заказа Вы будете перенаправлены на
                      защищенную платежную страницу Uniteller, где необходимо
                      будет ввести данные для оплаты заказа. После успешной
                      оплаты на указанную в форме оплаты электронную почту будет
                      направлен электронный чек с информацией о заказе и данными
                      по произведенной оплате.
                    </p>
                    <h4>Гарантии безопасности</h4>
                    <p className={styles.securityP}>
                      Безопасность процессинга Uniteller подтверждена
                      сертификатом стандарта безопасности данных индустрии
                      платежных карт PCI DSS. Надежность сервиса обеспечивается
                      интеллектуальной системой мониторинга мошеннических
                      операций, а также применением 3D Secure - современной
                      технологией безопасности интернет-платежей. Данные Вашей
                      карты вводятся на специальной защищенной платежной
                      странице. Передача информации в процессинговую компанию
                      Uniteller происходит с применением технологии шифрования
                      TLS. Дальнейшая передача информации осуществляется по
                      закрытым банковским каналам, имеющим наивысший уровень
                      надежности.
                    </p>
                    <h4>
                      Uniteller не передает данные Вашей карты магазину и иным
                      третьим лицам!
                    </h4>
                    <p className={styles.securityP}>
                      Если Ваша карта поддерживает технологию 3D Secure, для
                      осуществления платежа, Вам необходимо будет пройти
                      дополнительную проверку пользователя в банке-эмитенте
                      (банк, который выпустил Вашу карту). Для этого Вы будете
                      направлены на страницу банка, выдавшего карту. Вид
                      проверки зависит от банка. Как правило, это дополнительный
                      пароль, который отправляется в SMS, карта переменных
                      кодов, либо другие способы.
                    </p>
                  </div>
                  <StyledButton onClick={() => setSecurityPaymentPopup(false)}>
                    Закрыть
                  </StyledButton>
                </StyledPopupWrapper>
              </Layer>
            )}
          </>
        ) : null}

        <HiddenForm
          requestPayment={requestPayment}
          paymentFormKeys={paymentFormKeys}
          formButton={formButton}
        />

        {/*  {isInfoPopup && (
          <PopupInfoBasket closePopup={() => setIsInfoPopup(false)} />
        )} */}
      </StyledWrapper>
    );
  }
);
