import { Box, Grid } from "grommet";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useDataStore } from "../../hooks/useDataStore";

import styles from "./partners.module.scss";
import { Card } from "./Card";

export const Partners = observer(() => {
  const { getPartnersBanners, getBanners } = useDataStore();
  const mainPartnerArray = getBanners("partners_main");
  const partPartnersArray = getBanners("partners");

  useEffect(() => {
    getPartnersBanners();
    //eslint-disable-next-line
  }, []);

  return (
    <Box pad={{ vertical: "66px" }} gap="66px">
      <Grid columns={{ count: "fit", size: "200px" }} gap={{ row: "90px" }}>
        <Box gap="100px">
          <div className={styles.cards}>
            {mainPartnerArray.map((item, index) => (
              <Card item={item} key={index} />
            ))}
          </div>
          <div className={styles.cards} style={{ marginTop: "40px" }}>
            {partPartnersArray.map((item, index) => (
              <Card item={item} key={index} />
            ))}
          </div>
        </Box>
      </Grid>
    </Box>
  );
});
