import React, { useContext, useEffect, useState } from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import { useValidationDate } from "../../../customHooks/useValidationDate";
import { isMobileTabletResponsive, objectEqual } from "../../../utils/utils";
import { TextInput } from "../../Inputs/TextInput";
import { DatePicker } from "../../Inputs/DatePicker";
import { DefaultButton } from "../../DefaultButton";
import { convertToISO } from "../../../utils/date";

export const Data = ({ getDataMessage, setStatus, setMessage, setNewData }) => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  const [user, setUser] = useState(
    localStorage.userObj && JSON.parse(localStorage.userObj)
  );
  const [userName, setUserName] = useState(user?.name);
  const [lastName, setLastName] = useState(user?.last_name);
  const [secondName, setSecondName] = useState(user?.second_name);
  const [valueDate, handleDateChange, date, isValidDate] = useValidationDate(
    new Date(user?.birth_date)
  );

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setUser(localStorage.userObj && JSON.parse(localStorage.userObj));

    let timer = setTimeout(() => {
      setStatus(false);
      setMessage(``);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isLoading, setMessage, setStatus]);

  // основная функция отправки измененных данных / для обновления информации
  const changeDataHandler = (evt) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 3000);

    evt.preventDefault();
    // проверка новых/измененных данных
    const userNameChanges = userName && userName !== user?.name;
    const lastNameChanges = lastName && lastName !== user?.last_name;
    const secondNameChanges = secondName && secondName !== user?.second_name;
    const dateChanges = date && objectEqual(date, new Date(user?.birth_date));

    if (!isValidDate || !date) {
      setMessage("Введите дату в правильном формате!");
    }
    // если обновились все - данные - обновляем все или по отдельности
    if (
      userNameChanges &&
      lastNameChanges &&
      secondNameChanges &&
      dateChanges &&
      date
    ) {
      setNewData({
        name: userName,
        last_name: lastName,
        birth_date: convertToISO(valueDate),
        second_name: secondName,
      });
      handleDateChange(null);
      setLastName(null);
      setSecondName(null);
      setUserName(null);
    } else {
      if (userNameChanges && userName) {
        setNewData({ name: userName });
        setUserName(null);
      }
      if (secondNameChanges && secondName) {
        setNewData({ second_name: secondName });
        setSecondName(null);
      }
      if (lastNameChanges && lastName) {
        setNewData({ last_name: lastName });
        setLastName(null);
      }
      if (dateChanges && date) {
        setNewData({ birth_date: convertToISO(valueDate) });
        handleDateChange(null);
      }
      if (
        !userNameChanges &&
        !dateChanges &&
        !lastNameChanges &&
        !secondNameChanges &&
        date
      ) {
        setMessage("Данные не изменились!");

        setLoading(false);
      }
    }
  };

  const handleFocus = () => setMessage(``);

  return (
    <Box>
      <Box flex direction="column">
        <Box flex>
          <Box flex direction={isMobileWidth ? "column" : "row"} gap="1rem">
            <TextInput
              error=""
              handleChange={(event) => setUserName(event.target.value)}
              handleFocus={handleFocus}
              name="name"
              iconSrc="rect"
              id="profile-name"
              defaultValue={userName}
              iconHeight="40px"
              iconLeft="20px"
              iconTop="8px"
              valid=""
              label="Ваше имя"
            />
            <TextInput
              error=""
              handleChange={(event) => setLastName(event.target.value)}
              handleFocus={handleFocus}
              name="last-name"
              iconSrc="rect"
              id="profile-last-name"
              iconHeight="40px"
              iconLeft="20px"
              iconTop="8px"
              defaultValue={lastName}
              valid=""
              label="Ваша фамилия"
            />
          </Box>
          <Box pad={{ top: "1rem", bottom: "1rem" }}>
            <Text size="small">{getDataMessage}</Text>
          </Box>
          <Box
            margin={{ top: "20px", bottom: isMobileWidth && "20px" }}
            flex
            direction={isMobileWidth ? "column" : "row"}
            gap="30px"
          >
            <TextInput
              error=""
              handleChange={(event) => setSecondName(event.target.value)}
              handleFocus={handleFocus}
              name="second-name"
              iconSrc="rect"
              id="profile-second-name"
              iconHeight="40px"
              iconLeft="20px"
              iconTop="8px"
              defaultValue={secondName}
              valid=""
              label="Ваше отчество"
            />

            <DatePicker
              error=""
              value={valueDate}
              onChange={handleDateChange}
              onFocus={handleFocus}
              id="profile-birthDate"
              iconSrc="rect"
              iconHeight="40px"
              iconLeft="20px"
              iconTop="8px"
            />
          </Box>
        </Box>

        <Box
          flex
          height={{ max: "56px" }}
          margin={{ top: !isMobileWidth && "2rem" }}
          width={{ max: "406px" }}
        >
          <DefaultButton
            style={{
              height: "56px",
              padding: "10px",
              width: "100%",
              textAlign: "center",
              borderRadius: "8px",
            }}
            onClick={changeDataHandler}
          >
            Изменить личные данные
          </DefaultButton>
        </Box>
      </Box>
    </Box>
  );
};
